import payload_plugin_qLmFnukI99 from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt3_plugin_BdfRvWAAOY from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import primevue_plugin_egKpok8Auk from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import api_client_hHF76B0Hrp from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/plugins/api.client.ts";
import sweetalert2_21eHv6chX3 from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/plugins/sweetalert2.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt3_plugin_BdfRvWAAOY,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  api_client_hHF76B0Hrp,
  sweetalert2_21eHv6chX3
]