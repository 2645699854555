import { default as _91id_93d96awGRmGiMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/athlete/[id].vue?macro=true";
import { default as verify1Jn6J44EgKMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/email/verify.vue?macro=true";
import { default as forgot_45passwordzWQCOQbobAMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/forgot-password.vue?macro=true";
import { default as indexznHWndLGAgMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/index.vue?macro=true";
import { default as registration7PmDVwmDzzMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/registration.vue?macro=true";
import { default as _91token_93UeeOzJFsksMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/reset-password/[email]/[token].vue?macro=true";
import { default as _91slug_93ibIkdqfQdCMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/camps/[slug].vue?macro=true";
import { default as _91slug_93cAGbWARLU3Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/competitions/[slug].vue?macro=true";
import { default as indexyaQObzjuF6Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/competitions/index.vue?macro=true";
import { default as _91slug_932k98t1RFfPMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/events/[slug].vue?macro=true";
import { default as indexeR2VvbPTf4Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/events/index.vue?macro=true";
import { default as index3ThV2euQbuMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/index.vue?macro=true";
import { default as _91slug_93pIIIDFwh6BMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/master-classes/[slug].vue?macro=true";
import { default as _91slug_93BB2DZANQY5Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/media/[slug].vue?macro=true";
import { default as indexKG8iWX3lKWMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/media/index.vue?macro=true";
import { default as _91slug_93udmg87hnxPMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/[slug].vue?macro=true";
import { default as _91slug_93307nyawfjHMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/category/[slug].vue?macro=true";
import { default as indexXjOEa1waalMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/index.vue?macro=true";
import { default as _91id_93JJr3XFwupEMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/[id].vue?macro=true";
import { default as createIEFFMOystJMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/create.vue?macro=true";
import { default as update_45_91id_93albuLUGFc1Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/update-[id].vue?macro=true";
import { default as indexyCXgonkQJ9Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/[id]/index.vue?macro=true";
import { default as updateHQvxFZ5QMlMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/[id]/update.vue?macro=true";
import { default as createZToldxwbp3Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/create.vue?macro=true";
import { default as _91id_93edM7uqOv3HMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/[id].vue?macro=true";
import { default as createePSffHXuJeMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/create.vue?macro=true";
import { default as update_45idtrNjhiZCrSMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/update-id.vue?macro=true";
import { default as _91slug_93SaWjUqjhRzMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/p/[slug].vue?macro=true";
import { default as album_45_91id_93K3r5gNzULsMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/album-[id].vue?macro=true";
import { default as checkoutRsNVSxGtCCMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/checkout.vue?macro=true";
import { default as indexXWe5jQlV9HMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/index.vue?macro=true";
import { default as order_45idOOyVM3DndXMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/order-id.vue?macro=true";
import { default as searchFd9bxDgNVhMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/search.vue?macro=true";
import { default as _91id_9356pyKWEKjEMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/profile/extra-service/[id].vue?macro=true";
import { default as indexC5hN4GOAwgMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/profile/index.vue?macro=true";
import { default as ratingC1PxF2X1daMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/rating.vue?macro=true";
import { default as recordsZBsrEAclhJMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/records.vue?macro=true";
import { default as results91LJQht0bEMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/results.vue?macro=true";
import { default as _91slug_93oFF8qFELV2Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/[slug].vue?macro=true";
import { default as checkoutGi6FHBBe74Meta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/checkout.vue?macro=true";
import { default as homeLAXJBjvwUzMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/home.vue?macro=true";
import { default as indexsNvhCYxm1AMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/index.vue?macro=true";
import { default as _91id_93gIwg2PgOPnMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/order/[id].vue?macro=true";
import { default as tournaments4FQC2Z9PBjMeta } from "E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/tournaments.vue?macro=true";
export default [
  {
    name: "athlete",
    path: "/athlete/:id()",
    meta: _91id_93d96awGRmGiMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/athlete/[id].vue")
  },
  {
    name: "auth-email-verify",
    path: "/auth/email/verify",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/email/verify.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/forgot-password.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexznHWndLGAgMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/index.vue")
  },
  {
    name: "registration",
    path: "/auth/registration",
    meta: registration7PmDVwmDzzMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/registration.vue")
  },
  {
    name: "auth-reset-password-email-token",
    path: "/auth/reset-password/:email()/:token()",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/auth/reset-password/[email]/[token].vue")
  },
  {
    name: "camp",
    path: "/camps/:slug()",
    meta: _91slug_93ibIkdqfQdCMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/camps/[slug].vue")
  },
  {
    name: "competition",
    path: "/competitions/:slug()",
    meta: _91slug_93cAGbWARLU3Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/competitions/[slug].vue")
  },
  {
    name: "competitions",
    path: "/competitions",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/competitions/index.vue")
  },
  {
    name: "event",
    path: "/events/:slug()",
    meta: _91slug_932k98t1RFfPMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/index.vue")
  },
  {
    name: "master_class",
    path: "/master-classes/:slug()",
    meta: _91slug_93pIIIDFwh6BMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/master-classes/[slug].vue")
  },
  {
    name: "media_single",
    path: "/media/:slug()",
    meta: _91slug_93BB2DZANQY5Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/media/[slug].vue")
  },
  {
    name: "media",
    path: "/media",
    meta: indexKG8iWX3lKWMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/media/index.vue")
  },
  {
    name: "post",
    path: "/news/:slug()",
    meta: _91slug_93udmg87hnxPMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/[slug].vue")
  },
  {
    name: "post_category",
    path: "/news/category/:slug()",
    meta: _91slug_93307nyawfjHMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/category/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexXjOEa1waalMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/news/index.vue")
  },
  {
    name: "order-camp-id",
    path: "/order-camp/:id()",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/[id].vue")
  },
  {
    name: "order-camp-create",
    path: "/order-camp/create",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/create.vue")
  },
  {
    name: "order-camp-update-id",
    path: "/order-camp/update-:id()",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-camp/update-[id].vue")
  },
  {
    name: "order_event",
    path: "/order-event/:id()",
    meta: indexyCXgonkQJ9Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/[id]/index.vue")
  },
  {
    name: "order_event_update",
    path: "/order-event/:id()/update",
    meta: updateHQvxFZ5QMlMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/[id]/update.vue")
  },
  {
    name: "order_event_create",
    path: "/order-event/create",
    meta: createZToldxwbp3Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-event/create.vue")
  },
  {
    name: "order_master_class",
    path: "/order-master-class/:id()",
    meta: _91id_93edM7uqOv3HMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/[id].vue")
  },
  {
    name: "order_master_class_create",
    path: "/order-master-class/create",
    meta: createePSffHXuJeMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/create.vue")
  },
  {
    name: "order_master_class_update",
    path: "/order-master-class/update-id",
    meta: update_45idtrNjhiZCrSMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/order-master-class/update-id.vue")
  },
  {
    name: "page",
    path: "/p/:slug()",
    meta: _91slug_93SaWjUqjhRzMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/p/[slug].vue")
  },
  {
    name: "photobank-album-id",
    path: "/photobank/album-:id()",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/album-[id].vue")
  },
  {
    name: "photobank-checkout",
    path: "/photobank/checkout",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/checkout.vue")
  },
  {
    name: "photobank",
    path: "/photobank",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/index.vue")
  },
  {
    name: "photobank-order-id",
    path: "/photobank/order-id",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/order-id.vue")
  },
  {
    name: "photobank-search",
    path: "/photobank/search",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/photobank/search.vue")
  },
  {
    name: "profile-extra-service-id",
    path: "/profile/extra-service/:id()",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/profile/extra-service/[id].vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexC5hN4GOAwgMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/profile/index.vue")
  },
  {
    name: "rating",
    path: "/rating",
    meta: ratingC1PxF2X1daMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/rating.vue")
  },
  {
    name: "records",
    path: "/records",
    meta: recordsZBsrEAclhJMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/records.vue")
  },
  {
    name: "results",
    path: "/results",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/results.vue")
  },
  {
    name: "shop_product",
    path: "/shop/:slug()",
    meta: _91slug_93oFF8qFELV2Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/[slug].vue")
  },
  {
    name: "shop_checkout",
    path: "/shop/checkout",
    meta: checkoutGi6FHBBe74Meta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/checkout.vue")
  },
  {
    name: "shop-home",
    path: "/shop/home",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/home.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/index.vue")
  },
  {
    name: "shop_order",
    path: "/shop/order/:id()",
    meta: _91id_93gIwg2PgOPnMeta || {},
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/shop/order/[id].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("E:/Sites/swim4you.ru/Vue/s4y_nuxt_v3/pages/tournaments.vue")
  }
]